import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from '@zd/theme';

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 450px;
  max-width: 100%;
  background: ${theme.colors.lightGrey};
  border: 1px solid ${theme.colors.itemBorder};
  border-radius: 20px;
`;

const GoogleMap = props => {
  const { src, allowFullScreen, ...rest } = props;

  return (
    <Iframe
      src={src}
      frameBorder={0}
      allowFullScreen={allowFullScreen}
      aria-hidden={false}
      tabIndex={0}
      {...rest}
    />
  );
};

GoogleMap.propTypes = {
  src: PropTypes.string,
  allowFullScreen: PropTypes.bool,
};

GoogleMap.defaultProps = {
  src:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.6343313295256!2d-8.66491108465924!3d41.16432981775628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f7d21267bc5%3A0x9b2b66880a455cb8!2sAv.%20do%20Dr.%20Antunes%20Guimar%C3%A3es%2063%2C%204100-073%20Porto!5e0!3m2!1sen!2spt!4v1608069275219!5m2!1sen!2spt',
  allowFullScreen: false,
};

export default GoogleMap;
