import React from 'react';
import styled from '@emotion/styled';
import { BiPhone as PhoneIcon } from 'react-icons/bi';
import { AiOutlineMail as MailIcon } from 'react-icons/ai';
import { GoLocation as AddressIcon } from 'react-icons/go';

import useSiteMetadata from '@zd/hooks/useSiteMetadata';
import PageSEO from '@zd/components/page-seo';
import { Container, Row, Col } from '@zd/components/grid';
import Link from '@zd/components/link';
import BackLink from '@zd/components/back-link';
import ContactForm from '@zd/components/forms/contact-form';
import GoogleMap from '@zd/components/google-map';
import theme from '@zd/theme';

const InfoDiv = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: ${theme.colors.primaryBlue};
  color: white;
  padding: 35px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h1 {
    color: #fdf8f3;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 52px;
    margin-bottom: 8px;
  }

  & > p {
    color: #b1acbd;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 40px;
  }

  & > ul {
    & > li {
      margin-bottom: 24px;

      & > a {
        display: flex;

        & > svg {
          display: inline-block;
          margin-top: 8px;
        }

        & > span {
          display: inline-block;
          margin-left: 10px;
          color: white;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 32px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          color: ${theme.colors.white};

          & > svg {
            color: ${theme.colors.white};
          }
        }
      }
    }
  }

  ${theme.medias.gteLarge} {
    margin-bottom: 0;
  }
`;

const StyledBackLink = styled(BackLink)`
  margin-top: 0;
  margin-bottom: 2.5rem;
`;

const metaTitle = 'Informações de Contacto';
const metaDescription = 'Entre em contacto com a nossa equipa, de segunda a sábado, das 8h às 22h.';

export default props => {
  const siteMetadata = useSiteMetadata();

  return (
    <>
      <PageSEO {...props} title={metaTitle} description={metaDescription} />
      <Container>
        <Row>
          <Col lg="5">
            <InfoDiv>
              <h1>Informações de contacto</h1>
              <p>Entre em contacto com a nossa equipa, de segunda a sábado, das 8h às 22h.</p>
              <ul>
                {/*
                <li>
                  <Link
                    href={`tel:${siteMetadata.phone}`}
                    title="Contacto Telefónico da Cínica da Boavista"
                  >
                    <PhoneIcon />
                    <span>(+351) 932 239 668</span>
                  </Link>
                </li>
                */}
                <li>
                  <Link
                    href="mailto:joaovilasboas@clinicadaboavista.com"
                    title="Endereço de Email da Clínica da Boavista"
                  >
                    <MailIcon />
                    <span>joaovilasboas@clinicadaboavista.com</span>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://goo.gl/maps/GdBMnvxLRn15XdxZ8"
                    title="Morada da Clínica da Boavista"
                    target="_blank"
                  >
                    <AddressIcon />
                    <span>
                      Av. Dr. Antunes Guimarães, 63, 1º Esq
                      <br />
                      4100-079 Porto, Portugal
                    </span>
                  </Link>
                </li>
              </ul>
            </InfoDiv>
          </Col>
          <Col lg="7">
            <ContactForm
              submitLabel="Enviar mensagem"
              descriptionLabel="Mensagem"
              descriptionPlaceholder="Descreva qual o seu motivo de contacto"
              isBooking={false}
              showWhatsApp={false}
            />
          </Col>
        </Row>
        <br />
        <GoogleMap />
        <StyledBackLink />
      </Container>
    </>
  );
};
